import React, { useState, useEffect } from "react";
import styled from "styled-components";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import pinterest from "../images/pinterest.png";
import twitter from "../images/twitter.png";
import Http from "../helpers/Http";

const FooterComponent = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    try {
      Http.get("content/type/2")
        .then((response) => {
          setServices(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Footer>
      <FooterMenuContainer>
        <Grid>
          <Title>Menu</Title>
          <FooterMenu>
            <FooterMenuItemLink>
              <a href="/about">About</a>
            </FooterMenuItemLink>
            <FooterMenuItemLink>
              <a href="/services">Services</a>
            </FooterMenuItemLink>
            <FooterMenuItemLink>
              <a href="/hadith">Hadith</a>
            </FooterMenuItemLink>
            <FooterMenuItemLink>
              <a href="/events">Events</a>
            </FooterMenuItemLink>
            <FooterMenuItemLink>
              <a href="/news">News</a>
            </FooterMenuItemLink>
            <FooterMenuItemLink>
              <a href="/contact" />
              Contact
            </FooterMenuItemLink>
          </FooterMenu>
        </Grid>
        <Grid>
          <Title>Services</Title>
          <FooterMenu>
            {services.map((item, index) => {
              return (
                <FooterMenuItemLink key={index}>
                  <a href={`/detail/?id=${item.id}&type=SERVICE`}>
                    {item.title.substring(0, 30)}
                  </a>
                </FooterMenuItemLink>
              );
            })}
          </FooterMenu>
        </Grid>
        <Grid>
          <Title>Contact</Title>
          <FooterMenu>
            <FooterMenuItemLink href="tel:07912 781 492">
              Imran Ahmed 07912 781 492
            </FooterMenuItemLink>
            <FooterMenuItemLink href="tel:07833 891 942">
              Luqman Musa 07833 891 942
            </FooterMenuItemLink>
            <FooterMenuItemLink href="mailto:info@darwenmosque.co.uk">
              info@darwenmosque.co.uk
            </FooterMenuItemLink>
            <FooterMenuItemText>
              21-23 Victoria Street,
              <br />
              Darwen, BB3 3HB
            </FooterMenuItemText>
          </FooterMenu>
        </Grid>
        <Grid>
          <Title>Social</Title>
          <SocialLinks />
        </Grid>
      </FooterMenuContainer>
      <FooterDivider />
      <FooterCopyrightContainer>
        <FooterCopyright>
          Copyright © 2021 by{" "}
          <a href="https://sulaman.com">Sulaman Services Ltd</a> All rights
          reserved.
        </FooterCopyright>
      </FooterCopyrightContainer>
    </Footer>
  );
};

export default FooterComponent;

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <img src={facebook} alt="Facebook" />
      <img src={pinterest} alt="Pinterest" />
      <img src={instagram} alt="Instagram" />
      <img src={twitter} alt="Twitter" />
    </SocialLinksContainer>
  );
};

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    height: 32px;
    width: 32px;
  }

  a {
    text-decoration: none;
    margin: 0 4px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 48px auto;
`;
const Title = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.footer`
  background-color: #232323;
  color: #fff;
`;

export const FooterMenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 36px 4%;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;
export const FooterCopyrightContainer = styled.div`
  padding: 32px 0;
  display: flex;
  justify-content: center;
`;
export const FooterCopyright = styled.p``;
export const FooterDivider = styled.div`
  height: 1px;
  border-top: 1px solid rgba(87, 166, 143, 0.2);
  width: 100%;
`;

export const FooterMenu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FooterMenuItem = styled.li`
  margin: 4px auto;
  h2 {
    font-size: 1.5em;
    line-height: 1.35em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }
  &:hover {
    a {
      text-decoration: underline;
      color: var(--base-color);
      cursor: pointer;
    }
  }
`;

export const FooterMenuItemTitle = ({ children }) => (
  <FooterMenuItem>
    <h2>{children}</h2>
  </FooterMenuItem>
);

export const FooterMenuItemLink = ({ children, ...rest }) => (
  <FooterMenuItem>
    <a {...rest}>{children}</a>
  </FooterMenuItem>
);

export const FooterMenuItemText = ({ children }) => (
  <FooterMenuItem>
    <p>{children}</p>
  </FooterMenuItem>
);
